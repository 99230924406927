<template>
  <div class="scales-settings" v-loading="loading">

    <page-heading
      heading="Шкалы"
    >
      <div slot="right">
        <div class="el-row--flex">
          <library-scales-units-modal
            v-if="$canAndRulesPass(user, 'scales.edit_units')"
          >
            <el-button
              slot="reference"
              size="small"
              icon="fas fa-pen"
            >
              Редактирование единиц измерения
            </el-button>
          </library-scales-units-modal>

          <el-button
            v-if="$canAndRulesPass(user, $permissions.CONFIGURABLE_SCALES.CREATE)"
            style="margin-left: 20px"
            type="primary"
            size="small"
            icon="fas fa-plus"
            @click="createScaleTemplate"
          >
            Создать шкалу
          </el-button>
        </div>
      </div>
    </page-heading>


<!--    <el-row :gutter="40">-->
<!--      <el-col-->
<!--        :span="12"-->
<!--        v-for="template in scaleTemplates"-->
<!--        :key="template.id"-->
<!--      >-->
<!--        <el-card style="margin-bottom: 35px">-->
<!--          <ScaleTemplate-->
<!--            :template.sync="template"-->
<!--            @template-deleted="getScaleTemplates"-->
<!--          ></ScaleTemplate>-->
<!--        </el-card>-->
<!--      </el-col>-->
<!--    </el-row>-->


    <div class="templates-container">
      <el-card
        v-for="template in scaleTemplates"
        :key="template.id"
        class="template-card"
        style="margin-bottom: 35px"
      >
      <ScaleTemplate
        :template.sync="template"
        @template-deleted="getScaleTemplates"
      ></ScaleTemplate>
      </el-card>
    </div>

  </div>
</template>

<script>
import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import PageHeading from "@/components/PageHeading.vue";
import LibraryScalesUnitsModal from "@/components/library/LibraryScalesUnitsModal.vue";
import {mapGetters} from "vuex";
import ScaleTemplate from "@/components/configurableScales/settings/ScaleTemplate.vue";

export default {
  name: "scales-settings",
  components: {ScaleTemplate, LibraryScalesUnitsModal, PageHeading, ClickToEdit},
  props: {},
  watch: {},
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      loading: false,
      scaleTemplates: [],
    }
  },
  mounted() {
    this.getScaleTemplates();
  },
  methods: {
    getScaleTemplates(){
      this.loading = true;
      requestSender('get', 'scale/templates-list', {
        sort: 'name',
        with_disabled: 1,
        expand: 'permissions',
      })
        .then(data => {
          this.scaleTemplates = data.scales;
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    createScaleTemplate(){
      this.loading = true;
      requestSender('post', 'scale/create', {})
        .finally(()=>{
          this.getScaleTemplates();
        })
    },
  }
}
</script>


<style lang="scss" scoped>
.templates-container {
  -moz-column-count: 2;
  -moz-column-gap: 25px;
  -webkit-column-count: 2;
  -webkit-column-gap: 25px;
  column-count: 2;
  column-gap:25px;
}
.template-card {

}
</style>